import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Services
import { CurrentUserService } from '../current-user/current-user.service';

// Const & Utils
import { ELEMENTS_PER_PAGE } from '@app/core/const/initial-pages.const';
import {
  toSelectOptions,
  generateResponseDataPage
} from '@app/fmx-shared/utils/data-utils';

// Models
import { CurrentUser } from '@app/core/models/current-user.model';
import { SelectOption } from '@app/core/models/select-option.model';
import { DataPage } from '@app/fmx-shared/interfaces/data-page.interface';

const typeUserEndpoint = {
  operator: 'operators',
  user: 'users',
  doer: 'doers'
};

@Injectable()
export class UserService {
  constructor(
    private http: HttpClient,
    private currentUserService: CurrentUserService
  ) {}

  updateProfile(data: any, userId: string, userType: string): Observable<any> {
    const endpoint = `${typeUserEndpoint[userType]}/${userId}`;

    return this.http.patch(endpoint, data);
  }

  deleteUser(idUser: number): Observable<any> {
    const endpoint = `users/${idUser}`;

    return this.http.delete(endpoint);
  }

  changePassword(data: any, userId: string, userType: string): Observable<any> {
    const endpoint = `${typeUserEndpoint[userType]}/${userId}`;

    return this.http.put(endpoint, data);
  }

  recoveryPass(password: string, token: string): Observable<any> {
    const endpoint = `password/reset`;

    return this.http.post(endpoint, { password, token });
  }

  forgotPass(email: string): Observable<any> {
    const endpoint = 'password/forgot';

    return this.http.post(endpoint, { email });
  }

  getProfilelNotifications(): Observable<any> {
    const endpoint = 'profile/notifications_preferences';

    return this.http.get(endpoint);
  }

  updateProfileNotifications(preferenceId: string, data: any): Observable<any> {
    const endpoint = `profile/notifications_preferences/${preferenceId}`;

    return this.http.put(endpoint, data);
  }

  getVinculatedSpacesByPage(
    idUser: string,
    page: number,
    search: string
  ): Observable<DataPage> {
    let params = new HttpParams()
      .set('sort_by', 'name')
      .set('sort_dir', 'asc')
      .set('minimum_api', 'true')
      .set('per_page', ELEMENTS_PER_PAGE)
      .set('page', page.toString());

    if (search) {
      params = params.append('name', search);
    }

    const endpoint = `users/${idUser}/spaces`;

    return this.http
      .get(endpoint, { params })
      .pipe(map(generateResponseDataPage));
  }

  updateLAA(obj: any): Observable<any> {
    const endpoint = `${obj.type}/${obj.id}`;

    return this.http.patch(endpoint, obj.data);
  }

  updateFiscalInfo(obj: any): Observable<any> {
    const endpoint = `${obj.type}/${obj.id}/fiscal_info`;

    return this.http.patch(endpoint, obj.data);
  }

  deleteFiscalData(obj: any): Observable<any> {
    const endpoint = `${obj.type}/${obj.id}/fiscal_info`;

    return this.http.delete(endpoint, obj.data);
  }

  addUserVinculations(idUser: number, data: any): Observable<any> {
    const endpoint = `users/${idUser}/user_vinculations`;

    return this.http.post(endpoint, data);
  }

  deleteUserVinculations(
    idUser: number,
    idVinculation: number
  ): Observable<any> {
    const endpoint = `users/${idUser}/user_vinculations/${idVinculation}`;

    return this.http.delete(endpoint);
  }

  getComplaintsClient(
    filter: any,
    pagination: any,
    idClient: string
  ): Observable<any> {
    const endpoint = `users/${idClient}/complaints`;

    const params = {
      ...filter,
      ...pagination
    };

    return this.http.get(endpoint, { params });
  }

  getGroupsUser(): Observable<SelectOption[]> {
    const endpoint = `users/${this.currentUserService.currentUser.id}/groups`;
    const params = new HttpParams().set('sort_by', 'name');

    return this.http.get(endpoint, { params }).pipe(map(toSelectOptions));
  }

  getUserStore(): CurrentUser {
    return this.currentUserService.currentUser;
  }
}
