var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
// Services
import { CurrentUserService } from '../current-user/current-user.service';
// Const & Utils
import { ELEMENTS_PER_PAGE } from '@app/core/const/initial-pages.const';
import { toSelectOptions, generateResponseDataPage } from '@app/fmx-shared/utils/data-utils';
var typeUserEndpoint = {
    operator: 'operators',
    user: 'users',
    doer: 'doers'
};
var UserService = /** @class */ (function () {
    function UserService(http, currentUserService) {
        this.http = http;
        this.currentUserService = currentUserService;
    }
    UserService.prototype.updateProfile = function (data, userId, userType) {
        var endpoint = typeUserEndpoint[userType] + "/" + userId;
        return this.http.patch(endpoint, data);
    };
    UserService.prototype.deleteUser = function (idUser) {
        var endpoint = "users/" + idUser;
        return this.http.delete(endpoint);
    };
    UserService.prototype.changePassword = function (data, userId, userType) {
        var endpoint = typeUserEndpoint[userType] + "/" + userId;
        return this.http.put(endpoint, data);
    };
    UserService.prototype.recoveryPass = function (password, token) {
        var endpoint = "password/reset";
        return this.http.post(endpoint, { password: password, token: token });
    };
    UserService.prototype.forgotPass = function (email) {
        var endpoint = 'password/forgot';
        return this.http.post(endpoint, { email: email });
    };
    UserService.prototype.getProfilelNotifications = function () {
        var endpoint = 'profile/notifications_preferences';
        return this.http.get(endpoint);
    };
    UserService.prototype.updateProfileNotifications = function (preferenceId, data) {
        var endpoint = "profile/notifications_preferences/" + preferenceId;
        return this.http.put(endpoint, data);
    };
    UserService.prototype.getVinculatedSpacesByPage = function (idUser, page, search) {
        var params = new HttpParams()
            .set('sort_by', 'name')
            .set('sort_dir', 'asc')
            .set('minimum_api', 'true')
            .set('per_page', ELEMENTS_PER_PAGE)
            .set('page', page.toString());
        if (search) {
            params = params.append('name', search);
        }
        var endpoint = "users/" + idUser + "/spaces";
        return this.http
            .get(endpoint, { params: params })
            .pipe(map(generateResponseDataPage));
    };
    UserService.prototype.updateLAA = function (obj) {
        var endpoint = obj.type + "/" + obj.id;
        return this.http.patch(endpoint, obj.data);
    };
    UserService.prototype.updateFiscalInfo = function (obj) {
        var endpoint = obj.type + "/" + obj.id + "/fiscal_info";
        return this.http.patch(endpoint, obj.data);
    };
    UserService.prototype.deleteFiscalData = function (obj) {
        var endpoint = obj.type + "/" + obj.id + "/fiscal_info";
        return this.http.delete(endpoint, obj.data);
    };
    UserService.prototype.addUserVinculations = function (idUser, data) {
        var endpoint = "users/" + idUser + "/user_vinculations";
        return this.http.post(endpoint, data);
    };
    UserService.prototype.deleteUserVinculations = function (idUser, idVinculation) {
        var endpoint = "users/" + idUser + "/user_vinculations/" + idVinculation;
        return this.http.delete(endpoint);
    };
    UserService.prototype.getComplaintsClient = function (filter, pagination, idClient) {
        var endpoint = "users/" + idClient + "/complaints";
        var params = __assign({}, filter, pagination);
        return this.http.get(endpoint, { params: params });
    };
    UserService.prototype.getGroupsUser = function () {
        var endpoint = "users/" + this.currentUserService.currentUser.id + "/groups";
        var params = new HttpParams().set('sort_by', 'name');
        return this.http.get(endpoint, { params: params }).pipe(map(toSelectOptions));
    };
    UserService.prototype.getUserStore = function () {
        return this.currentUserService.currentUser;
    };
    return UserService;
}());
export { UserService };
