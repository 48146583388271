<div class="row" *ngIf="config.showThumbnails">
  <div class="ml-4 mt-3 spinner-loader" *ngIf="loader"></div>

  <div class="col-4" *ngFor="let file of listFilesGenerated; let i = index">
    <button
      (click)="removeFile(file)"
      class="fmx-btn fmx-btn-red fmx-btn-clear fmx-btn-xs fmx-btn-icon no-padding"
    >
      <mat-icon class="position-absolute" style="top: -5px; right: 5px"
        >delete</mat-icon
      >
    </button>
    <img
      [src]="routeIcon(file) | safe"
      class="img-fluid border rounded"
      (load)="onImageLoad(i)"
    />
  </div>
</div>
<div class="row mt-3">
  <div class="col-12">
    <button
      (click)="addFile()"
      [disabled]="loader"
      class="fmx-btn fmx-btn-sm fmx-btn-primary d-flex flex-v-center"
      [ngSwitch]="config.only_icon"
    >
      <mat-icon *ngSwitchCase="true">add</mat-icon>
      <span *ngSwitchCase="true">{{ config.text_button }}</span>
    </button>
    <input
      type="file"
      #file
      style="display: none"
      [accept]="config.accept"
      [multiple]="config.multiple"
      (change)="onFilesAdded()"
    />
  </div>
</div>
