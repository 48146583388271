/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i2 from "@angular/material/icon";
import * as i3 from "@angular/common";
import * as i4 from "../../pipes/safe/safe.pipe";
import * as i5 from "@angular/platform-browser";
import * as i6 from "./fmx-upload-files.component";
var styles_FmxUploadFilesComponent = [];
var RenderType_FmxUploadFilesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FmxUploadFilesComponent, data: {} });
export { RenderType_FmxUploadFilesComponent as RenderType_FmxUploadFilesComponent };
function View_FmxUploadFilesComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "ml-4 mt-3 spinner-loader"]], null, null, null, null, null))], null, null); }
function View_FmxUploadFilesComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "col-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "button", [["class", "fmx-btn fmx-btn-red fmx-btn-clear fmx-btn-xs fmx-btn-icon no-padding"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeFile(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "mat-icon", [["class", "position-absolute mat-icon"], ["role", "img"], ["style", "top: -5px; right: 5px"]], [[2, "mat-icon-inline", null]], null, null, i1.View_MatIcon_0, i1.RenderType_MatIcon)), i0.ɵdid(3, 638976, null, 0, i2.MatIcon, [i0.ElementRef, i2.MatIconRegistry, [8, null]], null, null), (_l()(), i0.ɵted(-1, 0, ["delete"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "img", [["class", "img-fluid border rounded"]], [[8, "src", 4]], [[null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = (_co.onImageLoad(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵppd(6, 1)], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 3).inline; _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i0.ɵnov(_v.parent.parent, 0), _co.routeIcon(_v.context.$implicit))); _ck(_v, 5, 0, currVal_1); }); }
function View_FmxUploadFilesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FmxUploadFilesComponent_2)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FmxUploadFilesComponent_3)), i0.ɵdid(4, 802816, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loader; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.listFilesGenerated; _ck(_v, 4, 0, currVal_1); }, null); }
function View_FmxUploadFilesComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i1.View_MatIcon_0, i1.RenderType_MatIcon)), i0.ɵdid(1, 638976, null, 0, i2.MatIcon, [i0.ElementRef, i2.MatIconRegistry, [8, null]], null, null), (_l()(), i0.ɵted(-1, 0, ["add"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).inline; _ck(_v, 0, 0, currVal_0); }); }
function View_FmxUploadFilesComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.config.text_button; _ck(_v, 1, 0, currVal_0); }); }
export function View_FmxUploadFilesComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i4.SafePipe, [i5.DomSanitizer]), i0.ɵqud(402653184, 1, { file: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FmxUploadFilesComponent_1)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 8, "div", [["class", "row mt-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 7, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 5, "button", [["class", "fmx-btn fmx-btn-sm fmx-btn-primary d-flex flex-v-center"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 16384, null, 0, i3.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FmxUploadFilesComponent_4)), i0.ɵdid(9, 278528, null, 0, i3.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i3.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FmxUploadFilesComponent_5)), i0.ɵdid(11, 278528, null, 0, i3.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i3.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵeld(12, 0, [[1, 0], ["file", 1]], null, 0, "input", [["style", "display: none"], ["type", "file"]], [[8, "accept", 0], [8, "multiple", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFilesAdded() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.config.showThumbnails; _ck(_v, 3, 0, currVal_0); var currVal_2 = _co.config.only_icon; _ck(_v, 7, 0, currVal_2); var currVal_3 = true; _ck(_v, 9, 0, currVal_3); var currVal_4 = true; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.loader; _ck(_v, 6, 0, currVal_1); var currVal_5 = _co.config.accept; var currVal_6 = _co.config.multiple; _ck(_v, 12, 0, currVal_5, currVal_6); }); }
export function View_FmxUploadFilesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "fmx-upload-files", [], null, null, null, View_FmxUploadFilesComponent_0, RenderType_FmxUploadFilesComponent)), i0.ɵdid(1, 49152, null, 0, i6.FmxUploadFilesComponent, [], null, null)], null, null); }
var FmxUploadFilesComponentNgFactory = i0.ɵccf("fmx-upload-files", i6.FmxUploadFilesComponent, View_FmxUploadFilesComponent_Host_0, { config: "config" }, { fileGenerated: "fileGenerated", actionRemove: "actionRemove" }, []);
export { FmxUploadFilesComponentNgFactory as FmxUploadFilesComponentNgFactory };
