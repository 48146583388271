import { Pipe, PipeTransform } from '@angular/core';
import { STATUS_NAVISION } from '@app/core/const/status-navision.const';

@Pipe({
  name: 'billingStatus'
})
export class BillingStatusPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case STATUS_NAVISION.pending:
        return 'Pendiente de sincronizar';
      case STATUS_NAVISION.synchronized:
        return 'Sincronizada';
      case STATUS_NAVISION.error:
        return 'Error de sincronización';
      default:
        return 'No sincronizable';
    }
  }
}
