export function getFormData(fileObject: any, keyParent: string): FormData {
  const formData = new FormData();

  Object.keys(fileObject).forEach((key: string) => {
    const value =
      fileObject[`${key}`] === 'null' || fileObject[`${key}`] === null
        ? ''
        : fileObject[`${key}`];

    formData.append(`${keyParent}[${key}]`, value);
  });

  return formData;
}
