import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import heic2any from 'heic2any';
import * as _ from 'lodash';

// Models
import { ConfigUploadFiles } from '@app/core/models/config-upload-files.interface';

@Component({
  selector: 'fmx-upload-files',
  templateUrl: './fmx-upload-files.component.html'
})
export class FmxUploadFilesComponent {
  @ViewChild('file') file: any;
  @Output() fileGenerated = new EventEmitter<File>();
  @Output() actionRemove = new EventEmitter<File>();

  @Input() config: ConfigUploadFiles;

  listFilesGenerated = [];
  loader = false;

  routeIcon(file: File): string {
    let iconRoute: string;

    if (file.type.includes('video')) {
      iconRoute = 'assets/images/icons/video.png';
    } else if (file.type.includes('excel') || file.type.includes('xml')) {
      iconRoute = 'assets/images/icons/ms-excel.png';
    } else if (file.type.includes('pdf')) {
      iconRoute = 'assets/images/icons/pdf.png';
    } else {
      iconRoute = (file as any).fileString;
    }

    return iconRoute;
  }

  addFile() {
    this.file.nativeElement.click();
  }

  removeFile(file: any) {
    this.actionRemove.emit(file.file);

    // tslint:disable:only-arrow-functions
    // tslint:disable:no-function-expression
    _.remove(this.listFilesGenerated, function (item: any) {
      return item.fileString === file.fileString;
    });
  }

  onFilesAdded() {
    const files = this.file.nativeElement.files;

    if (files) {
      this.loader = true;

      // tslint:disable-next-line:no-increment-decrement
      for (let i = 0; i < files.length; i++) {
        if (this.isPermitType(files[i])) {
          if (files[i].type.includes('heic')) {
            this.convertHeicToBase64(files[i]);
          } else {
            this.readerFile(files[i]);
          }
        }
      }
    }
  }

  onImageLoad(index: number): void {
    this.listFilesGenerated[index].loader = false;
    this.loader = this.listFilesGenerated.some((item: any) => item.loader);
  }

  private convertHeicToBase64(file: File) {
    heic2any({
      blob: file,
      toType: 'image/png'
    }).then((convertedFile: any) => {
      this.readerFile(convertedFile);
    });
  }

  private readerFile(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event: any) => {
      this.fileGenerated.emit(file);
      this.createFile(file, reader.result);
    };
  }

  private isPermitType(file: File): boolean {
    // tslint:disable-next-line:no-reserved-keywords
    const type = file.type;

    return (
      this.config.accept.includes(type) ||
      type.includes('image/') ||
      type.includes('application/pdf') ||
      type.includes('video/quicktime')
    );
  }

  private createFile(file: File, fileString: string | ArrayBuffer) {
    const fileGenerated = {
      fileString,
      description: '',
      type: file.type,
      loader: true,
      file
    };

    if (!this.config.multiple) {
      this.listFilesGenerated = [];
    }

    this.listFilesGenerated.push(fileGenerated);
  }
}
